import React from 'react';
import { useState, useEffect } from 'react';
import { DataTable } from 'mantine-datatable';
import { Box } from '@mantine/core';
import { showNotification } from '@mantine/notifications';
import { API } from 'aws-amplify';
import InsertUpdateRsvpDialog from '../fragments/insertUpdateRsvpDialog';
import { Modal, Button } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { useMemo } from 'react';
import { useDebouncedValue } from '@mantine/hooks';
import { TextInput, ActionIcon } from '@mantine/core';
import { IconSearch, IconX } from '@tabler/icons-react';
import { sortBy } from 'lodash';
import { CheckIcon } from '@mantine/core';
import { IconCheck, IconSquareX, IconEdit } from '@tabler/icons-react';
import { Stack, Group } from '@mantine/core';

function RsvpGuestList({ }) {
    const [rsvpData, setRsvpData] = useState([]);
    const [rsvpDataOriginal, setRsvpDataOriginal] = useState([]);

    const [guestData, setGuestData] = useState([]);

    const [currentRecord, setcurrentRecord] = useState({});
    const [modalOpened, { open, close }] = useDisclosure(false);
    const [records, setRecords] = useState([]);
    const [query, setQuery] = useState('');
    const [debouncedQuery] = useDebouncedValue(query, 200);


    useEffect(() => {
        refresh()
    }, [currentRecord]);

    function openModal(rec) {
        setcurrentRecord(rec)
        open()
    }
    function closeModal() {
        setcurrentRecord({})
        close()

    }
    function refresh() {
        API.get("AdminApi", "/rsvp")
            .then(response => {
                setRsvpDataOriginal(response)
            })
            .catch(error => {
            })
        API.get("AdminApi", "/guests")
            .then(response => {
                setGuestData(response);

            })
            .catch(error => {
            })
    }

    useEffect(() => {
        setRecords(
            rsvpData.filter(({ name }) => {
                if (
                    debouncedQuery !== '' &&
                    !`${name}`.toLowerCase().includes(debouncedQuery.trim().toLowerCase())
                ) {
                    return false;
                }
                return true;
            })
        );
    }, [debouncedQuery, rsvpData]);

    useEffect(() => {
        const flatGuestsDetailArray = rsvpDataOriginal.flatMap((guest) =>
            Object.entries(guest.guestsDetail).map(([id, detail]) => {
                const additionalData = guestData.find((data) => data.id === guest.id);
                return {
                    id: guest.id,
                    detailId: id,
                    ...detail,
                    guestData: additionalData || null, // null if not found
                };
            })
        );
        console.log(flatGuestsDetailArray)
        setQuery('')
        setRsvpData(flatGuestsDetailArray);
        setRecords(flatGuestsDetailArray)
    }, [rsvpDataOriginal, guestData]);


    return (
        <>
            <div style={{ margin: 'auto', width: '10%' }}>
                <h1>Gästeliste</h1>
            </div>
            <div style={{ width: "80%", margin: "auto", marginTop: "50px" }}>
                <div style={{ display: "flex", justifyContent: "space-between", marginBottom: "20px" }}>
                    <Button color='green' onClick={() => openModal()}>Add entry</Button>
                    <Button variant='outline' color='green' onClick={() => refresh()}>Refresh</Button>
                </div>
                <DataTable
                    idAccessor="name"
                    withTableBorder
                    borderRadius="sm"
                    withColumnBorders
                    striped
                    highlightOnHover
                    // provide data
                    records={records || []}
                    // define columns
                    columns={[
                        {
                            accessor: 'id',
                            // this column has a custom title
                            title: '#',
                            // right-align column
                            textAlign: 'right',
                        },
                        {
                            accessor: 'name',
                            filter: (
                                <TextInput
                                    label="Name"
                                    description=""
                                    placeholder="Gast suchen"
                                    leftSection={<IconSearch size={16} />}
                                    rightSection={
                                        <ActionIcon size="sm" variant="transparent" c="dimmed" onClick={() => setQuery('')}>
                                            <IconX size={14} />
                                        </ActionIcon>
                                    }
                                    value={query}
                                    onChange={(e) => setQuery(e.currentTarget.value)}
                                />
                            ),
                            filtering: query !== '',
                        },
                        {
                            accessor: 'Standesamt',
                            render: (record) => (
                                record?.guestData?.invitedEvents?.ceremony ? (
                                    record.ceremony ? <IconCheck width={'20'} /> : <IconSquareX width={'20'} />
                                ) : (
                                    "-"
                                )
                            ),
                            textAlign: 'center'
                        },
                        {
                            accessor: 'Feier',
                            render: (record) => (
                                record?.guestData?.invitedEvents?.party ? (
                                    record.reception ? <IconCheck width={'20'} /> : <IconSquareX width={'20'} />
                                ) : (
                                    "-"
                                )
                            ),
                            textAlign: 'center'
                        },
                        {
                            accessor: 'Toskana',
                            render: (record) => (
                                record?.guestData?.invitedEvents?.italy ? (
                                    record.italy ? <IconCheck width={'20'} /> : <IconSquareX width={'20'} />
                                ) : (
                                    "-"
                                )
                            ),
                            textAlign: 'center'
                        },
                        {
                            accessor: 'days',
                            title: "Nächte in Italien",
                            render: (record) => (
                                record?.guestData?.invitedEvents?.italy ? (
                                    record.days ? (
                                        record.days
                                    ) : ("-")
                                ) : (
                                    "-"
                                )
                            ),
                            textAlign: 'center'
                        },
                        {
                            accessor: 'breakfast',
                            title: "Frühstück",
                            render: (record) => (
                                record?.guestData?.invitedEvents?.italy ? (
                                    record.italy ? (
                                        record.breakfast ? <IconCheck width={'20'} /> : <IconSquareX width={'20'} />
                                    ) : ("-")
                                ) : (
                                    "-"
                                )
                            ),
                            textAlign: 'center'
                        },
                        /*
                        {
                            accessor: 'aktion',
                            textAlign: 'center',
                            render: (record) => (
                                <Group gap={4} justify="center" wrap="nowrap">
                                    <ActionIcon
                                        size="sm"
                                        variant="subtle"
                                        color="blue"
                                        onClick={() => openModal(record)}
                                    >
                                        <IconEdit size={16} />
                                    </ActionIcon>
                                </Group>
                            ),
                        },

*/
                        /*
                        {
                            accessor: 'invitedEvents',
                            render: ({ invitedEvents }) => (
                                Object.keys(invitedEvents).map((event, index) => (
                                    invitedEvents[event] === true ? (
                                        <li key={index}>{event}</li>
                                    )
                                        : (null)
                                ))
                            ),
                        },
                        */

                    ]}
                />
            </div>

            <Modal opened={modalOpened} title={currentRecord?.name} onClose={closeModal} centered>
                <InsertUpdateRsvpDialog record={currentRecord} close={closeModal} refresh={refresh} allGuests={guestData} />
            </Modal>
        </>
    );
}

export default RsvpGuestList;
/*
<Modal opened={modalOpened} title={currentRecord?.name} onClose={closeModal} centered>
<InsertUpdateGuestDialog record={currentRecord} />
</Modal>*/