import React from 'react';
import { useState, useEffect } from 'react';
import { API } from 'aws-amplify';
import { Button } from '@mantine/core';

function Statisticlist({ }) {
    const [rsvpData, setRsvpData] = useState([]);
    const [rsvpDataOriginal, setRsvpDataOriginal] = useState([]);

    const [guestData, setGuestData] = useState([]);

    const [currentRecord, setcurrentRecord] = useState({});




    useEffect(() => {
        refresh()
    }, [currentRecord]);

    function refresh() {
        API.get("AdminApi", "/rsvp")
            .then(response => {
                setRsvpDataOriginal(response)
            })
            .catch(error => {
            })
        API.get("AdminApi", "/guests")
            .then(response => {
                setGuestData(response);

            })
            .catch(error => {
            })
    }



    useEffect(() => {
        const flatGuestsDetailArray = rsvpDataOriginal.flatMap((guest) =>
            Object.entries(guest.guestsDetail).map(([id, detail]) => {
                const additionalData = guestData.find((data) => data.id === guest.id);
                return {
                    id: guest.id,
                    detailId: id,
                    ...detail,
                    guestData: additionalData || null, // null if not found
                };
            })
        );
        setRsvpData(flatGuestsDetailArray);
    }, [rsvpDataOriginal, guestData]);


    return (
        <>
            <div style={{ margin: 'auto', width: '10%' }}>
                <h1>Statistik</h1>
            </div>
            <div style={{ width: "80%", margin: "auto", marginTop: "50px" }}>
                <div style={{ display: "flex", justifyContent: "space-between", marginBottom: "20px" }}>
                    <Button variant='outline' color='green' onClick={() => refresh()}>Refresh</Button>
                </div>
                <div style={{ width: "50%", margin: "auto", marginTop: "50px" }}>
                    <table style={{ width: "100%", margin: "auto", marginTop: "50px" }}>
                        <tr>
                            <th>Einladungen:</th>
                            <td>{guestData?.length}</td>

                        </tr>
                        <tr>
                            <th>Zusagen:</th>
                            <td>
                                {
                                    (rsvpDataOriginal.filter((item) => item.attendance === true))?.length
                                }
                            </td>

                        </tr>
                        <tr>
                            <th>Standesamt:</th>
                            <td>
                                {
                                    (rsvpData.filter((item) => item.ceremony === true))?.length
                                }
                            </td>
                        </tr>
                        <tr>
                            <th>Feier:</th>
                            <td>
                                {
                                    (rsvpData.filter((item) => item.reception === true))?.length
                                }
                            </td>
                        </tr>
                        <tr>
                            <th>Italien:</th>
                            <td>
                                {
                                    (rsvpData.filter((item) => item.italy === true))?.length
                                }
                            </td>
                        </tr>
                        <tr>
                            <th>Italien 5 Nächte:</th>
                            <td>
                                {
                                    (rsvpData.filter((item) => item.days === "5"))?.length
                                }
                            </td>
                        </tr>
                        <tr>
                            <th>Italien 3 Nächte:</th>
                            <td>
                                {
                                    (rsvpData.filter((item) => item.days === "3"))?.length
                                }
                            </td>
                        </tr>

                        <tr>
                            <th>Frühstück:</th>
                            <td>
                                {
                                    (rsvpData.filter((item) => item.breakfast === true))?.length
                                }
                            </td>
                        </tr>
                    </table>
                </div>
            </div>


        </>
    );
}

export default Statisticlist;
/*
<Modal opened={modalOpened} title={currentRecord?.name} onClose={closeModal} centered>
<InsertUpdateGuestDialog record={currentRecord} />
</Modal>*/