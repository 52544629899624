import React from 'react';
import { useState, useEffect } from 'react';
import { DataTable } from 'mantine-datatable';
import { showNotification } from '@mantine/notifications';
import { API } from 'aws-amplify';
import Guestlist from '../components/guestlist';
import { isNotEmpty, useForm } from '@mantine/form';
import { TextInput, Button, Group, Box, Checkbox } from '@mantine/core';
import { randomId } from '@mantine/hooks';
import { Modal } from '@mantine/core';
import { IconCopy, IconTrash } from '@tabler/icons-react';
import { CopyButton } from '@mantine/core';
import { Icon } from '@aws-amplify/ui-react';
import { Tooltip } from '@mantine/core';
import { ActionIcon } from '@mantine/core';
import { IconCheck } from '@tabler/icons-react';
import { Text } from '@mantine/core';
import { QRCode } from 'react-qrcode-logo';
import { Center } from '@mantine/core';

function SharingInfoDialog({ record, close, refresh }) {
    const link = `https://fiona-und-dominik.de/?id=${record.id}`

    const downloadCode = () => {
        const canvas = document.getElementById("qrcode");
        if (canvas) {
            const pngUrl = canvas
                .toDataURL("image/png")
                .replace("image/png", "image/octet-stream");
            let downloadLink = document.createElement("a");
            downloadLink.href = pngUrl
            downloadLink.download = `${record.name}.png`;
            document.body.appendChild(downloadLink);
            downloadLink.click();
            document.body.removeChild(downloadLink);
        }
    }

    return (
        <>
            <Center >

                <Box mx="auto" >
                    <Text>{`ID: ${record?.id}`}</Text>
                    <TextInput value={link} disabled rightSection={
                        <CopyButton value={link} timeout={2000}>
                            {({ copied, copy }) => (
                                <Tooltip label={copied ? 'Copied' : 'Copy'} withArrow position="right">
                                    <ActionIcon color={copied ? 'teal' : 'gray'} variant="subtle" onClick={copy}>
                                        {copied ? (
                                            <IconCheck />
                                        ) : (
                                            <IconCopy />
                                        )}
                                    </ActionIcon>
                                </Tooltip>
                            )}
                        </CopyButton>

                    }
                    >
                    </TextInput>
                        <QRCode
                            value={link}      // here you should keep the link/value(string) for which you are generation promocode
                            size={250}        // the dimension of the QR code (number)
                            eyeRadius={10}    // radius of the promocode eye
                            id={"qrcode"}
                        />
                        <Button onClick={() => downloadCode()}>
                            Download Code
                        </Button>
                </Box>
            </Center>

        </>
    );
}

export default SharingInfoDialog;
