import logo from './logo.svg';
import './App.css';
import '@aws-amplify/ui-react/styles.css';

import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";
import { API } from 'aws-amplify';
import { withAuthenticator } from '@aws-amplify/ui-react';
import Guests from './pages/guests';
import Rsvplist from './components/rsvplist';
import '@mantine/core/styles.layer.css';
import 'mantine-datatable/styles.layer.css';
import { AppShell, Burger, Group, NavLink, Text } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { Link } from 'react-router-dom';
import { Outlet } from 'react-router-dom';
import Scanner from './pages/scanner';
import RsvpGuests from './pages/rsvpguests';
import Statistics from './pages/statistics';
import Grocerieslist from './components/Grocerieslist';
import Pictures from './components/Pictures';

const router = createBrowserRouter([
  {
    element: <AS />,
    children: [
      {
        path: "/",
        element: <Guests />,
      },
      {
        path: "/guests",
        element: <Guests />,
      },
      {
        path: "/rsvp",
        element: <Rsvplist />,
      },
      {
        path: "/rsvpguests",
        element: <RsvpGuests />,
      },
      {
        path: "/statistic",
        element: <Statistics />,
      },
      {
        path: "/scanner",
        element: <Scanner />,
      },
      {
        path: "/groceries",
        element: <Grocerieslist />,
      },
      {
        path: "/pictures",
        element: <Pictures />,
      },
    ]
  },

]);

function App() {
  return (
    <RouterProvider router={router} />

  );
}

function AS() {
  const [opened, { toggle }] = useDisclosure();

  return (
    <>
      <AppShell
        header={{ height: 40 }}
        navbar={{ width: 150, breakpoint: 'sm', collapsed: { mobile: !opened } }}
        padding="md"
      >
        <AppShell.Header hiddenFrom='sm' >
          <Group h="100%" px="md">
            <Burger opened={opened} onClick={toggle} hiddenFrom="sm" size="sm" />
          </Group>
        </AppShell.Header>

        <AppShell.Navbar p="md">
          <div style={{ fontSize: "20px", display: "contents", lineHeight: "2" }}>

            <NavLink
              href="guests"
              label="Einladungen"
            />
            <NavLink
              href="rsvp"
              label="RSVP"
            />
            <NavLink
              href="rsvpguests"
              label="Gästeliste"
            />
            <NavLink
              href="statistics"
              label="Statistik"
            />
            <NavLink
              href="scanner"
              label="Scanner"
            />
            <NavLink
              href="groceries"
              label="Einkäufe"
            />
            <NavLink
              href="pictures"
              label="Bilder"
            />

          </div>

        </AppShell.Navbar>

        <AppShell.Main><Outlet /></AppShell.Main>
      </AppShell>
    </>
  )
}

export default withAuthenticator(App, { hideSignUp: true });
