import React from 'react';
import { useState, useEffect } from 'react';
import { DataTable } from 'mantine-datatable';
import { showNotification } from '@mantine/notifications';
import { API } from 'aws-amplify';
import Guestlist from '../components/guestlist';
import { isNotEmpty, useForm } from '@mantine/form';
import { TextInput, Button, Group, Box, Checkbox, Select } from '@mantine/core';
import { randomId } from '@mantine/hooks';
import { Textarea } from '@mantine/core';
import { IconTrash } from '@tabler/icons-react';


function InsertUpdateGuestDialog({ record, close, refresh }) {
    const [success, setSuccess] = useState(true);

    const roomNames = ["Azzuro", "Viola", "Giallo", "Verde", "Arancio", "Rosa", "Moka", "Rosso", "Soppalco", "Baita", "Covo"]

    function submitForm(values) {
        //console.log(values)
        let formObject = {
            id: values.id,
            salutation: values.salutation,
            name: values.name,
            allowedTotalGuests: values.allowedTotalGuests,
            invitedEvents: {
                ceremony: values.ceremony,
                party: values.party,
                italy: values.italy
            },
            address: {
                street: values.street,
                city: values.city,
                zip: values.zip
            },
            guests: values.guests,
            room: values.room,
            thanktext: values.thanktext,
            attendance: values.attendance
        }
        API.put("AdminApi", "/guests", { body: formObject })
            .then(response => {
                refresh()
                close()
            })
            .catch(error => {
                setSuccess(false)
            })
    }

    function deleteItem() {
        API.del("AdminApi", "/guests/object/" + form.values.id)
            .then(response => {
                refresh()
                close()
            })
            .catch(error => {
                setSuccess(false)
            })
    }

    const form = useForm({
        initialValues: {
            id: record?.id || "",
            salutation: record?.salutation || "",
            name: record?.name || "",
            allowedTotalGuests: record ? parseInt(record.allowedTotalGuests) : "",
            ceremony: record?.invitedEvents?.ceremony || false,
            party: record?.invitedEvents?.party || false,
            italy: record?.invitedEvents?.italy || false,
            street: record?.address?.street || "",
            city: record?.address?.city || "",
            zip: record?.address?.zip || "",
            guests: record?.guests || [],
            room: record?.room || "",
            thanktext: record?.thanktext || "",
            attendance: record?.attendance || false


        },
    });

    return (
        <>
            <Box maw={340} mx="auto">

                <form onSubmit={form.onSubmit((values) => submitForm(values))}>
                    <TextInput label="Id" placeholder="Id" required {...form.getInputProps('id')} />

                    <TextInput label="Anrede" placeholder="Anrede" required {...form.getInputProps('salutation')} />
                    <TextInput mt="md" label="Name" placeholder="Name" required {...form.getInputProps('name')} />
                    <TextInput mt="md" label="Anzahl Gäste" placeholder="Anzahl Gäste" type='number' required {...form.getInputProps('allowedTotalGuests')} />
                    {/*
                    {record?.invitedEvents?.italy === true ? (
                        <Select mt="md" label="Wohnung" placeholder="Wohnung" data={roomNames}  {...form.getInputProps('room')} />
                    ) : (null)}
                        */}
                    <Group justify="center" mt="xl" mb="xl">
                        <Checkbox label="Standesamt" {...form.getInputProps('ceremony', { type: 'checkbox' })} />
                        <Checkbox label="Party" {...form.getInputProps('party', { type: 'checkbox' })} />
                        <Checkbox label="Italien" {...form.getInputProps('italy', { type: 'checkbox' })} />
                    </Group>
                    <Checkbox label="Teilnahme" {...form.getInputProps('attendance', { type: 'checkbox' })} />
                    <Textarea autosize label="Danksagung" required {...form.getInputProps('thanktext')} w={"100%"} />
                    {/*
                    <TextInput label="Straße" placeholder="Straße" required {...form.getInputProps('street')} />
                    <Group justify='space-between'>
                        <TextInput w={"45%"} mt="md" label="PLZ" placeholder="PLZ" required  {...form.getInputProps('zip')} />
                        <TextInput w={"45%"} mt="md" label="Ort" placeholder="Ort" required {...form.getInputProps('city')} />
                    </Group>

                    <table width={"100%"}>
                        {
                            form.values.guests ? (
                                form.values.guests.map((guest, index) => (
                                    <Group mt="sm">
                                        <tr>
                                            <td>
                                                <TextInput mt="sm" width={"20px"} label="Vorname" placeholder="Vorname" required {...form.getInputProps(`guests.${index}.givenname`)} />
                                                <TextInput mt="sm" width={"20px"} label="Nachname" placeholder="Nachname" required {...form.getInputProps(`guests.${index}.surname`)} />
                                            </td>
                                            <td textAlign={"center"} style={{ verticalAlign: "middle", alignItems: 'center' }}>
                                                <Button
                                                    variant="outline"
                                                    color='red'
                                                    onClick={() => {
                                                        let guests = form.values.guests.splice(index, 1)
                                                        //delete form.values.guestsDetail[detailKey]
                                                        form.setFieldValue(`guestsDetail`, guests)
                                                    }
                                                    }
                                                    mt="sm"
                                                >
                                                    <IconTrash />
                                                </Button>
                                            </td>
                                        </tr>
                                    </Group>
                                ))
                            )
                                : (null)
                        }
                    </table>
                    
                    <Button
                        variant="outline"
                        color='green'
                        onClick={() =>
                            form.setFieldValue(`guests.${form.values.guests.length}`, {
                                givenname: "",
                                surname: ""
                            })

                        }
                        mt="sm"
                    >
                        Hinzufügen
                    </Button>
*/}
                    <Box style={{ backgroundColor: 'lightcoral', textAlign: 'center', marginTop: '10px', display: success ? 'none' : 'block' }}>
                        Submit failed
                    </Box>
                    <Group justify="center" mt="xl">

                        <Button type="submit" disabled={!form.isDirty()}>Submit</Button>
                        <Button
                            variant="outline"
                            color='red'
                            onClick={() =>
                                deleteItem()
                            }
                            disabled={!record}
                        >
                            Löschen
                        </Button>
                        <Button
                            variant="outline"
                            color='red'
                            onClick={() =>
                                form.reset()
                            }
                        >
                            Reset
                        </Button>
                    </Group>
                </form>
            </Box>
        </>
    );
}

export default InsertUpdateGuestDialog;
