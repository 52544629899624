import React from 'react';
import { useState, useEffect } from 'react';
import { DataTable } from 'mantine-datatable';
import { Box } from '@mantine/core';
import { showNotification } from '@mantine/notifications';
import { API } from 'aws-amplify';
import InsertUpdateGuestDialog from '../fragments/insertUpdateGuestDialog';
import SharingInfoDialog from '../fragments/sharingInfoDialog';
import { Modal, Button } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { useMemo } from 'react';
import { useDebouncedValue } from '@mantine/hooks';
import { TextInput, ActionIcon } from '@mantine/core';
import { IconSearch, IconX } from '@tabler/icons-react';
import { sortBy } from 'lodash';
import { CheckIcon } from '@mantine/core';
import { IconCheck, IconSquareX, IconQrcode } from '@tabler/icons-react';
import { IconEdit } from '@tabler/icons-react';
import { Group } from '@mantine/core';
import { Stack } from '@mantine/core';
import { Text } from '@mantine/core';

function Guestlist({ }) {
    const [guestData, setGuestData] = useState([]);
    const [currentRecord, setcurrentRecord] = useState({});
    const [rsvpData, setRsvpData] = useState({});

    const [modalOpened, { open, close }] = useDisclosure(false);
    const [modalInfoOpened, modalInfoHanlders] = useDisclosure(false);
    const [records, setRecords] = useState([]);
    const [query, setQuery] = useState('');
    const [debouncedQuery] = useDebouncedValue(query, 200);


    useEffect(() => {
        refresh()
    }, [currentRecord]);

    function openModal(rec) {
        setcurrentRecord(rec)
        open()
    }
    function closeModal() {
        setcurrentRecord({})
        close()

    }

    function openInfoModal(rec) {
        setcurrentRecord(rec)
        modalInfoHanlders.open()
    }
    function closeInfoModal() {
        setcurrentRecord({})
        modalInfoHanlders.close()
    }
    function refresh() {
        API.get("AdminApi", "/guests")
            .then(response => {
                setQuery('')
                setGuestData(response);
                setRecords(response)

            })
            .catch(error => {
            })
        API.get("AdminApi", "/rsvp")
            .then(response => {
                setRsvpData(response);
            })
            .catch(error => {
            })
    }

    useEffect(() => {
        setRecords(
            guestData.filter(({ name }) => {
                if (
                    debouncedQuery !== '' &&
                    !`${name}`.toLowerCase().includes(debouncedQuery.trim().toLowerCase())
                ) {
                    return false;
                }
                return true;
            })
        );
    }, [debouncedQuery, guestData]);



    return (
        <>
            <div style={{ margin: 'auto', width: '10%' }}>
                <h1>Gäste</h1>
            </div>
            <div style={{ width: "80%", margin: "auto", marginTop: "50px" }}>
                <div style={{ display: "flex", justifyContent: "space-between", marginBottom: "20px" }}>
                    <Button color='green' onClick={() => openModal()}>Add entry</Button>
                    <Button variant='outline' color='green' onClick={() => refresh()}>Refresh</Button>
                </div>
                <DataTable
                    idAccessor="id"
                    withTableBorder
                    borderRadius="sm"
                    withColumnBorders
                    striped
                    highlightOnHover
                    // provide data
                    records={records || []}
                    // define columns
                    columns={[
                        {
                            accessor: 'id',
                            // this column has a custom title
                            title: '#',
                            // right-align column
                            textAlign: 'right',
                        },
                        {
                            accessor: 'salutation',
                            title: 'Anrede'
                        },
                        {
                            accessor: 'name',
                            filter: (
                                <TextInput
                                    label="Name"
                                    description=""
                                    placeholder="Gast suchen"
                                    leftSection={<IconSearch size={16} />}
                                    rightSection={
                                        <ActionIcon size="sm" variant="transparent" c="dimmed" onClick={() => setQuery('')}>
                                            <IconX size={14} />
                                        </ActionIcon>
                                    }
                                    value={query}
                                    onChange={(e) => setQuery(e.currentTarget.value)}
                                />
                            ),
                            filtering: query !== '',
                        },
                        {
                            accessor: 'allowedTotalGuests',
                            textAlign: 'center',
                            title: 'Anzahl Gäste'
                        },
                        /*
                        {
                            accessor: 'invitedEvents',
                            render: ({ invitedEvents }) => (
                                Object.keys(invitedEvents).map((event, index) => (
                                    invitedEvents[event] === true ? (
                                        <li key={index}>{event}</li>
                                    )
                                        : (null)
                                ))
                            ),
                        },
                        */
                        {
                            accessor: 'invitedEvents',
                            title: "Standesamt",
                            render: ({ invitedEvents }) => (
                                invitedEvents.ceremony ? <IconCheck width={'20'} /> : <IconSquareX width={'20'} />
                            ),
                            textAlign: 'center',
                        },
                        {
                            accessor: 'invitedEvents',
                            title: "Party",
                            render: ({ invitedEvents }) => (
                                invitedEvents.party ? <IconCheck width={'20'} /> : <IconSquareX width={'20'} />
                            ),
                            textAlign: 'center',
                        },
                        {
                            accessor: 'invitedEvents',
                            title: "Italien",
                            render: ({ invitedEvents }) => (
                                invitedEvents.italy ? <IconCheck width={'20'} /> : <IconSquareX width={'20'} />
                            ),
                            textAlign: 'center',
                        },
                        {
                            accessor: 'aktion',
                            textAlign: 'center',
                            render: (record) => (
                                <Group gap={4} justify="center" wrap="nowrap">
                                    <ActionIcon
                                        size="sm"
                                        variant="subtle"
                                        color="blue"
                                        onClick={() => openModal(record)}
                                    >
                                        <IconEdit size={16} />
                                    </ActionIcon>
                                    <ActionIcon
                                        size="sm"
                                        variant="subtle"
                                        color="blue"
                                        onClick={() => openInfoModal(record)}
                                    >
                                        <IconQrcode size={16} />
                                    </ActionIcon>
                                </Group>
                            ),
                        },
                    ]}
                    rowExpansion={{
                        content: ({ record }) => (
                            <Stack gap={6} ml={20}>
                                <Text size="lg">Adresse:</Text>
                                <Text>
                                    {`${record?.address?.street}`}
                                </Text>
                                <Text>
                                    {`${record?.address?.city} ${record?.address?.zip}`}
                                </Text>
                                <Text size="lg">
                                    Gäste
                                </Text>
                                {/* 
                                <Group gap={6}>
                                    <table>
                                        {record.guests.map((guest, index) => (
                                            <tr key={index}>
                                                <td><Text>{record?.guests[index]?.givenname}</Text></td>
                                                <td><Text>{record?.guests[index]?.surname}</Text></td>

                                            </tr>

                                        ))}
                                    </table>
                                </Group>
                                */}
                            </Stack>
                        ),
                    }}
                />
            </div>

            <Modal opened={modalOpened} title={currentRecord?.name} onClose={closeModal} centered>
                <InsertUpdateGuestDialog record={currentRecord} close={closeModal} refresh={refresh} />
            </Modal>
            <Modal opened={modalInfoOpened} title={currentRecord?.name} onClose={closeInfoModal} centered>
                <SharingInfoDialog record={currentRecord} close={closeInfoModal} refresh={refresh} />
            </Modal>
        </>
    );
}

export default Guestlist;
/*
<Modal opened={modalOpened} title={currentRecord?.name} onClose={closeModal} centered>
<InsertUpdateGuestDialog record={currentRecord} />
</Modal>*/