import React from 'react';
import { useState, useEffect } from 'react';
import { DataTable } from 'mantine-datatable';
import { Box } from '@mantine/core';
import { showNotification } from '@mantine/notifications';
import { API } from 'aws-amplify';
import RsvpGuestList from '../components/rsvpguestlist';
function RsvpGuests({ }) {

    return (
        <RsvpGuestList/>
    );
}

export default RsvpGuests;
