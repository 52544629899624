import React from 'react';
import { useState, useEffect } from 'react';
import { DataTable } from 'mantine-datatable';
import { Box } from '@mantine/core';
import { showNotification } from '@mantine/notifications';
import { API } from 'aws-amplify';
import Guestlist from '../components/guestlist';

import { QrScanner } from '@yudiel/react-qr-scanner';

function Scanner({ }) {
    const [data, setData] = useState('No result');
    const [guests, setGuests] = useState([]);
    const [foundGuest, setFoundGuest] = useState({});


    useEffect(() => {
        API.get("AdminApi", "/guests")
            .then(response => {
                setGuests(response)

            })
            .catch(error => {
                console.log(error)

            })
    }, []);
    useEffect(() => {
        try {
            const urlParams = new URLSearchParams(new URL(data).search);
            const id = urlParams.get('id');
            const foundObject = guests.find(item => item.id === id);
            setFoundGuest(foundObject)
            console.log(foundObject)
        }
        catch { }
    }, [data, guests]);


    return (
        <>
            <QrScanner
                onDecode={(result) => setData(result)}
                onError={(error) => console.log(error?.message)}
            />
            <div>
                <table>
                    <tr>
                        <td>Name</td>
                        <td>{foundGuest?.name}</td>
                    </tr>
                    <tr>
                        <td>Gäste</td>
                        <td>{foundGuest?.allowedTotalGuests}</td>
                    </tr>
                    <tr>
                        <td>Anrede</td>
                        <td>{foundGuest?.salutation}</td>
                    </tr>
                    <tr>
                        <td>Events</td>
                        <td>
                            <ul>
                            {
                                foundGuest?.invitedEvents?.ceremony === true ? (<li>Standesamt</li>) : ("")
                            }
                            {
                                foundGuest?.invitedEvents?.party === true ? (<li>Party</li>) : ""
                            }
                            {
                                foundGuest?.invitedEvents?.italy === true ? (<li>Italien</li>) : ""
                            }                         
                         </ul>
                         </td>
                    </tr>
                </table>
            </div>
        </>
    );
}

export default Scanner;
