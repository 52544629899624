import React from 'react';
import { useState, useEffect } from 'react';
import { DataTable } from 'mantine-datatable';
import { Box } from '@mantine/core';
import { showNotification } from '@mantine/notifications';
import { API } from 'aws-amplify';
import InsertUpdateGuestDialog from '../fragments/insertUpdateGuestDialog';
import SharingInfoDialog from '../fragments/sharingInfoDialog';
import { Modal, Button } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { useMemo } from 'react';
import { useDebouncedValue } from '@mantine/hooks';
import { TextInput, ActionIcon } from '@mantine/core';
import { IconSearch, IconX } from '@tabler/icons-react';
import { sortBy } from 'lodash';
import { CheckIcon } from '@mantine/core';
import { IconCheck, IconSquareX, IconQrcode } from '@tabler/icons-react';
import { IconEdit } from '@tabler/icons-react';
import { Group } from '@mantine/core';
import { Stack } from '@mantine/core';
import { Text } from '@mantine/core';
import { Radio } from '@mantine/core';
import { CSVDownload, CSVLink } from "react-csv";



function Grocerieslist({ }) {
    const [guestData, setGuestData] = useState([]);
    const [currentRecord, setcurrentRecord] = useState({});
    const [rsvpData, setRsvpData] = useState({});
    const [groceriesData, setGroceriesData] = useState([]);
    const [groceriesDisplayData, setGroceriesDisplayData] = useState([]);
    const [filterView, setFilterView] = useState("shop");
    const [tableColumns, setTableColumns] = useState([]);




    const [modalOpened, { open, close }] = useDisclosure(false);
    const [modalInfoOpened, modalInfoHanlders] = useDisclosure(false);
    const [records, setRecords] = useState([]);
    const [query, setQuery] = useState('');
    const [debouncedQuery] = useDebouncedValue(query, 200);


    useEffect(() => {
        refresh()
    }, [currentRecord]);

    function openModal(rec) {
        setcurrentRecord(rec)
        open()
    }
    function closeModal() {
        setcurrentRecord({})
        close()

    }

    function openInfoModal(rec) {
        setcurrentRecord(rec)
        modalInfoHanlders.open()
    }
    function closeInfoModal() {
        setcurrentRecord({})
        modalInfoHanlders.close()
    }
    function refresh() {
        console.log("ref")
        API.get("AdminApi", "/groceries")
            .then(response => {
                setGroceriesData(response);
            })
            .catch(error => {
            })
        API.get("AdminApi", "/guests")
            .then(response => {
                setQuery('')
                setGuestData(response);
                setRecords(response)

            })
            .catch(error => {
            })
        API.get("AdminApi", "/rsvp")
            .then(response => {
                setRsvpData(response);
            })
            .catch(error => {
            })

    }

    useEffect(() => {
        setRecords(
            guestData.filter(({ name }) => {
                if (
                    debouncedQuery !== '' &&
                    !`${name}`.toLowerCase().includes(debouncedQuery.trim().toLowerCase())
                ) {
                    return false;
                }
                return true;
            })
        );
    }, [debouncedQuery, guestData]);

    useEffect(() => {
        if (groceriesData) {
            var requestedItems = ""
            switch (filterView) {
                case 'shop':
                    requestedItems = groceriesData
                        .filter(item => item.type === "requested")
                        .reduce((acc, item) => {
                            // Check if the product already exists in the accumulator
                            let existingProduct = acc.find(product => product.name === item.name);
                            // If the product doesn't exist, add it to the accumulator
                            if (!existingProduct) {
                                acc.push({
                                    name: item.name,
                                    totalQuantity: parseFloat(item.quantity),
                                    unit: item.unit
                                });
                            } else {
                                // If the product exists, update its total quantity and requested quantity
                                existingProduct.totalQuantity += parseFloat(item.quantity);
                            }

                            return acc;
                        }, [])
                        .sort((a, b) => a.name.localeCompare(b.name));
                    setGroceriesDisplayData(requestedItems)
                    setTableColumns([
                        {
                            accessor: 'name',
                            title: 'Produkt'
                        },
                        {
                            accessor: 'totalQuantity',
                            title: 'Menge'
                        },
                        {
                            accessor: 'unit',
                            title: 'Einheit'
                        }
                    ])
                    console.log(requestedItems)
                    break
                case 'distribute':
                    requestedItems = groceriesData
                        .filter(item => item.type === "requested")
                        .reduce((acc, item) => {
                            // Check if the product with the same name and room already exists in the accumulator
                            let existingProduct = acc.find(product => product.name === item.name && product.room === item.room);

                            // If the product doesn't exist, add it to the accumulator
                            if (!existingProduct) {
                                acc.push({
                                    name: item.name,
                                    totalQuantity: parseInt(item.quantity),
                                    unit: item.unit,
                                    room: item.room
                                });
                            } else {
                                // If the product exists, update its total quantity
                                existingProduct.totalQuantity += parseInt(item.quantity);
                            }

                            return acc;
                        }, [])
                        .sort((a, b) => {
                            // Sort first by room
                            let roomComparison = a.room.localeCompare(b.room);
                            // If rooms are the same, sort by name
                            return roomComparison === 0 ? a.name.localeCompare(b.name) : roomComparison;
                        });

                    console.log(requestedItems)
                    setGroceriesDisplayData(requestedItems)
                    setTableColumns([
                        {
                            accessor: 'name',
                            title: 'Produkt'
                        },
                        {
                            accessor: 'totalQuantity',
                            title: 'Menge'
                        },
                        {
                            accessor: 'unit',
                            title: 'Einheit'
                        },
                        {
                            accessor: 'room',
                            title: 'Zimmer'
                        }
                    ])
                    break

                case 'calculate':
                    requestedItems = groceriesData
                        .filter(item => item.type === "requested")
                        .reduce((acc, item) => {
                            // Find the corresponding guest using the id from guestData
                            let guest = guestData.find(guest => guest.id === item.requester);
                            let requesterName = guest ? guest.name : "Unknown";

                            // Check if the item already exists in the accumulator array
                            let existingItem = acc.find(i => i.name === item.name && i.requester === item.requester);

                            // If the item doesn't exist, add it to the accumulator array
                            if (!existingItem) {
                                acc.push({
                                    name: item.name,
                                    totalQuantity: parseInt(item.quantity),
                                    requester: item.requester,
                                    requesterName: requesterName,
                                    unit: item.unit
                                });
                            } else {
                                // If the item exists, update its total quantity
                                existingItem.totalQuantity += parseInt(item.quantity);
                            }

                            return acc;
                        }, [])
                        .sort((a, b) => {
                            // Sort first by room
                            let requesterComparison = a.requesterName.localeCompare(b.requesterName);
                            // If rooms are the same, sort by name
                            return requesterComparison === 0 ? a.name.localeCompare(b.name) : requesterComparison;
                        });
                        const requesterMap = guestData.reduce((map, guest) => {
                            map[guest.id] = guest.name;
                            return map;
                        }, {});
                        
                        // Map the requesterName to each item
                        requestedItems = requestedItems.map(item => ({
                            ...item,
                            requesterName: requesterMap[item.requester] || "Unknown"
                        }));
                    console.log(requestedItems)
                    setGroceriesDisplayData(requestedItems)
                    setTableColumns([
                        {
                            accessor: 'name',
                            title: 'Produkt'
                        },
                        {
                            accessor: 'totalQuantity',
                            title: 'Menge'
                        },
                        {
                            accessor: 'unit',
                            title: 'Einheit'
                        },
                        {
                            accessor: 'requester',
                            title: 'Besteller'
                        },
                        {
                            accessor: 'requesterName',
                            title: 'Besteller'
                        },
                    ])
                    break

                default:
                    return
            }


        }

    }, [filterView, groceriesData, guestData]);



    return (
        <>
            <div style={{ margin: 'auto', width: '10%' }}>
                <h1>Einkäufe</h1>
            </div>
            <div style={{ width: "80%", margin: "auto", marginTop: "50px" }}>
                <div style={{ display: "flex", justifyContent: "space-between", marginBottom: "20px" }}>
                    {/*<Button color='green' onClick={() => openModal()}>Add entry</Button>*/}
                    <Button variant='outline' color='green' onClick={() => refresh()}>Refresh</Button>
                    <Button variant='outline' color='green'>
                        <CSVLink data={groceriesDisplayData} filename={`groceries-${filterView}.csv`} target="_blank" style={{color:"inherit"}}> Download</CSVLink>
                        
                    </Button>


                </div>
                <div>
                    <Group justify="flex-end" mt="md" style={{ marginBottom: "10px" }}>
                        <Radio.Group
                            value={filterView}
                            onChange={setFilterView}
                            name="filterView"
                            label="Ansicht"
                        >
                            <Group mt="xs">
                                <Radio value="shop" label="Einkaufen" />
                                <Radio value="distribute" label="Verteilen" />
                                <Radio value="calculate" label="Abrechnen" />
                            </Group>
                        </Radio.Group>
                    </Group>

                </div>

                <DataTable
                    idAccessor="id"
                    withTableBorder
                    borderRadius="sm"
                    withColumnBorders
                    striped
                    highlightOnHover
                    // provide data
                    records={groceriesDisplayData || []}
                    // define columns
                    columns={tableColumns}

                />
            </div>

            <Modal opened={modalOpened} title={currentRecord?.name} onClose={closeModal} centered>
                <InsertUpdateGuestDialog record={currentRecord} close={closeModal} refresh={refresh} />
            </Modal>
            <Modal opened={modalInfoOpened} title={currentRecord?.name} onClose={closeInfoModal} centered>
                <SharingInfoDialog record={currentRecord} close={closeInfoModal} refresh={refresh} />
            </Modal>
        </>
    );
}

export default Grocerieslist;
/*
<Modal opened={modalOpened} title={currentRecord?.name} onClose={closeModal} centered>
<InsertUpdateGuestDialog record={currentRecord} />
</Modal>*/