import React from 'react';
import { useState, useEffect } from 'react';
import { DataTable } from 'mantine-datatable';
import { showNotification } from '@mantine/notifications';
import { API } from 'aws-amplify';
import Guestlist from '../components/guestlist';
import { isNotEmpty, useForm } from '@mantine/form';
import { TextInput, Button, Group, Box, Checkbox, Textarea } from '@mantine/core';
import { randomId } from '@mantine/hooks';
import { Modal } from '@mantine/core';
import { useCombobox, Combobox, InputBase, Input } from '@mantine/core';
import { IconTrash } from '@tabler/icons-react';


function InsertUpdateRsvpDialog({ record, close, refresh, allGuests }) {
    const [success, setSuccess] = useState(true);
    const [comboboxValues, setComboboxValues] = useState([]);
    const [comboboxSearch, setComboboxSearch] = useState("");

    const [selectedGuestId, setSelectedGuestId] = useState();

    const combobox = useCombobox();

    useEffect(() => {
        let array = []
        allGuests?.filter((item) => item?.name?.toLowerCase().includes(comboboxSearch.toLowerCase().trim()))
            .map((guest, index) => (
                array.push(
                    <Combobox.Option value={guest.id} key={index}>
                        {guest.name}
                    </Combobox.Option>)
            )
            );
        setComboboxValues(array)
    }, [allGuests, comboboxSearch]);


    function submitForm(values) {
        let formObject = {
            id: values.id,
            name: values.name,
            attendance: values.attendance,
            guests: Object.keys(form.values.guestsDetail).length,
            guestsDetail: values.guestsDetail,
            email: values.email,
            phone: values.phone,
            italy: record?.italy || {},
            comment:values.comment
        }
        API.put("AdminApi", "/rsvp", { body: formObject })
            .then(response => {
                refresh()
                close()
            })
            .catch(error => {
                setSuccess(false)
            })

    }

    function deleteItem() {
        API.del("AdminApi", "/rsvp/object/" + form.values.id)
            .then(response => {
                refresh()
                close()
            })
            .catch(error => {
                setSuccess(false)
            })
    }

    const form = useForm({
        initialValues: {
            id: record?.id || "",
            name: record?.name || "",
            attendance: record?.attendance || "",
            email: record?.email || "",
            phone: record?.phone || "",
            guests: record ? parseInt(record.guests) : "",
            guestsDetail: record?.guestsDetail,
            comment:record?.comment || ""
        },
    });


    useEffect(() => {
        if (selectedGuestId) {
            let selectedGuest = allGuests.find(item => item.id === selectedGuestId);
            form.setValues({
                id: selectedGuest?.id,
                name: selectedGuest?.name,
                attendance: "",
                email: "",
                phone: "",
                guests: "",
                guestsDetail: {}
            })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [allGuests, selectedGuestId]);

    return (
        <>
            <Box maw={340} mx="auto">

                <form onSubmit={form.onSubmit((values) => submitForm(values))}>
                    {!record ? (
                        <Combobox
                            store={combobox}
                            onOptionSubmit={(val) => {
                                setSelectedGuestId(val)
                                combobox.closeDropdown();
                            }}

                        >
                            <Combobox.Target>
                                <InputBase
                                    component="button"
                                    pointer
                                    rightSection={<Combobox.Chevron />}
                                    onClick={() => combobox.toggleDropdown()}
                                >
                                    {selectedGuestId || <Input.Placeholder>Pick value</Input.Placeholder>}
                                </InputBase>
                            </Combobox.Target>

                            <Combobox.Dropdown>
                                <Combobox.Search
                                    value={comboboxSearch}
                                    onChange={(event) => setComboboxSearch(event.currentTarget.value)}
                                    placeholder="Search guests"
                                />
                                <Combobox.Options>{comboboxValues}</Combobox.Options>
                            </Combobox.Dropdown>
                        </Combobox>
                    ) : (null)
                    }

                    <TextInput mt="md" label="Name" placeholder="Name" required {...form.getInputProps('name')} disabled={!record} />
                    <TextInput mt="md" disabled label="Anzahl Gäste" placeholder="Anzahl Gäste" type='number' required {...form.getInputProps('guests')} />
                    <TextInput mt="md" label="Email" placeholder="Email" type='email' required {...form.getInputProps('email')} />
                    <TextInput mt="md" label="Telefon" placeholder="Telefon" type='number' required {...form.getInputProps('phone')} />

                    <Checkbox mt={"md"} label="Attendance" {...form.getInputProps(`attendance`, { type: 'checkbox' })} />

                    <table>
                        {
                            form.values.guestsDetail ? (
                                Object.keys(form.values.guestsDetail).map((detailKey, detailIndex) => (
                                    <Group mt="sm">
                                        <tr>
                                            <td>
                                                <TextInput mt="md" width={"20px"} label="Name" placeholder="Name" required {...form.getInputProps(`guestsDetail.${detailKey}.name`)} />
                                                <Checkbox label="Standesamt" {...form.getInputProps(`guestsDetail.${detailKey}.ceremony`, { type: 'checkbox' })} />
                                                <Checkbox label="Party" {...form.getInputProps(`guestsDetail.${detailKey}.reception`, { type: 'checkbox' })} />
                                                <Checkbox label="Italien" {...form.getInputProps(`guestsDetail.${detailKey}.italy`, { type: 'checkbox' })} />
                                            </td>
                                            <td textAlign={"center"} style={{ verticalAlign: "middle", alignItems: 'center' }}>
                                                <Button
                                                    variant="outline"
                                                    color='red'
                                                    onClick={() => {
                                                        let gd = form.values.guestsDetail
                                                        delete gd[detailKey]
                                                        //delete form.values.guestsDetail[detailKey]
                                                        form.setFieldValue(`guestsDetail`, gd)
                                                    }
                                                    }
                                                    mt="sm"
                                                >
                                                    <IconTrash />
                                                </Button>
                                            </td>
                                        </tr>
                                    </Group>
                                ))
                            )
                                : (null)
                        }
                    </table>

                    <Button
                        variant="outline"
                        color='green'
                        onClick={() =>
                            form.setFieldValue(`guestsDetail.guestsDetail_${Object.keys(form.values.guestsDetail).length + 1}`, {
                                name: "",
                                ceremony: false,
                                reception: false,
                                italy: false,
                            })

                        }
                        mt="sm"
                    >
                        Hinzufügen
                    </Button>
                    <Textarea mt="md" label="Kommentar" placeholder=""  {...form.getInputProps('comment')} />

                    <Box style={{ backgroundColor: 'lightcoral', textAlign: 'center', marginTop: '10px', display: success ? 'none' : 'block' }}>
                        Submit failed
                    </Box>
                    <Group justify="center" mt="xl">

                        <Button type="submit" disabled={!form.isDirty()}>Submit</Button>
                        <Button
                            variant="outline"
                            color='red'
                            onClick={() =>
                                deleteItem()
                            }
                            disabled={!record}
                        >
                            Löschen
                        </Button>
                        <Button
                            variant="outline"
                            color='red'
                            onClick={() =>
                                form.reset()
                            }
                        >
                            Reset
                        </Button>
                    </Group>
                </form>
            </Box>
        </>
    );
}

export default InsertUpdateRsvpDialog;
