import React, { useState, useEffect } from 'react';
import { DataTable } from 'mantine-datatable';
import { Image, Modal, Button, Text, Group, Container } from '@mantine/core';
import { API } from 'aws-amplify';
import { Tabs, rem } from '@mantine/core';


const getPictures = async (folder) => {
    console.log(folder)
    const response = await API.get('AdminApi', `/pictures/${folder}`);
    return response;
}

function Pictures() {
    const [pictures, setPictures] = useState([]);
    const [selectedPicture, setSelectedPicture] = useState(null);
    const [modalOpen, setModalOpen] = useState(false);
    const [currentIndex, setCurrentIndex] = useState(0);
    const [currentTab, setCurrentTab] = useState('userupload');


    useEffect(() => {
        const fetchPictures = async (folder) => {
            const response = await getPictures(folder);
            console.log(response)
            setPictures(response);
        }
        if (modalOpen === false) {
            fetchPictures(currentTab);
        }
    }, [currentTab, modalOpen]);

    const handleRowClick = (index) => {
        setSelectedPicture(pictures[index]);
        setCurrentIndex(index);
        setModalOpen(true);
    }

    const handleButtonClick = async (action) => {
        // Perform action based on button clicked
        console.log(`Performing action: ${action}`);

        let success = true

        try {
            const response = await API.post('AdminApi', '/pictures/action', {
                body: { action, key: selectedPicture.Key }
            });
            console.log(response);
            success = true
            // If action is 'download', open the URL in a new tab
            if (action === 'download' && response.url) {
                window.open(response.url, '_blank');
            }
        } catch (error) {
            success = false
            console.error(error);
        }

        if (success === true) {
            // Move to next picture
            const nextIndex = currentIndex + 1;
            if (nextIndex < pictures.length) {
                setSelectedPicture(pictures[nextIndex]);
                setCurrentIndex(nextIndex);
            } else {
                setModalOpen(false);
            }
        }
    }

    return (
        <>
            <div style={{ margin: 'auto', width: '100%', textAlign: 'center' }}>
                <h1>Bilder</h1>
            </div>
            <Container>
                <Tabs value={currentTab} onChange={setCurrentTab}>
                    <Tabs.List>
                        <Tabs.Tab value="userupload">
                            Upload
                        </Tabs.Tab>
                        <Tabs.Tab value="cologne">
                            Köln
                        </Tabs.Tab>
                        <Tabs.Tab value="italy">
                            Italien
                        </Tabs.Tab>
                        <Tabs.Tab value="archive">
                            Archiv
                        </Tabs.Tab>
                    </Tabs.List>

                </Tabs>
                <DataTable
                    idAccessor={"Key"}
                    records={pictures}
                    onRowClick={({ record, index, event }) => handleRowClick(index)}
                    columns={[
                        {
                            accessor: 'Key',
                            title: 'name',
                            textAlign: 'left',
                        },
                        {
                            title: "",
                            accessor: 'thumbnailUrl',
                            render: ({ thumbnailUrl }) => (
                                <Image mah={300} src={thumbnailUrl} alt="Thumbnail" fit="cover" />
                            ),
                        },
                    ]}
                />
            </Container>
            {selectedPicture && (
                <Modal opened={modalOpen} onClose={() => setModalOpen(false)} title={selectedPicture.Key}>
                    <Group>
                        <Text>Hochgeladen von: </Text>
                        <Text fw={700}>{selectedPicture.uploadername}</Text>
                    </Group>
                    <Group>
                        <Text>Vermutete Location: </Text>
                        <Text fw={700}>{selectedPicture.location === "cologne" ? "Köln" : selectedPicture.location === "italy" ? "Italien" : "N/A"}</Text>
                    </Group>
                    <Image src={selectedPicture.thumbnailUrl} alt="Thumbnail" fit="cover" />
                    <Group mt={"md"} mb={"md"} align='center' justify='center'>
                        <Button onClick={() => handleButtonClick('publish_cologne')}>Veröffentlichen nach Köln</Button>
                        <Button onClick={() => handleButtonClick('publish_italy')}>Veröffentlichen nach Italien</Button>
                        <Button onClick={() => handleButtonClick('publish_italy-vacation')}>Veröffentlichen nach Urlaub</Button>
                    </Group>
                    <Group mt={"md"} mb={"md"} align='center' justify='center'>
                        <Button onClick={() => handleButtonClick('archive')}>Archivieren</Button>
                        <Button color="red" onClick={() => handleButtonClick('delete')}>Löschen</Button>
                        <Button onClick={() => handleButtonClick('download')}>Download</Button>
                    </Group>
                </Modal>
            )}
        </>
    );
}

export default Pictures;