import React from 'react';
import { useState, useEffect } from 'react';
import { DataTable } from 'mantine-datatable';
import { Box } from '@mantine/core';
import { showNotification } from '@mantine/notifications';
import { API } from 'aws-amplify';
import InsertUpdateRsvpDialog from '../fragments/insertUpdateRsvpDialog';
import { Modal, Button } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { useMemo } from 'react';
import { useDebouncedValue } from '@mantine/hooks';
import { TextInput, ActionIcon } from '@mantine/core';
import { IconSearch, IconX } from '@tabler/icons-react';
import { sortBy } from 'lodash';
import { CheckIcon } from '@mantine/core';
import { IconCheck, IconSquareX, IconEdit } from '@tabler/icons-react';
import { Stack, Group } from '@mantine/core';

function Rsvplist({ }) {
    const [rsvpData, setRsvpData] = useState([]);
    const [guestData, setGuestData] = useState([]);

    const [currentRecord, setcurrentRecord] = useState({});
    const [modalOpened, { open, close }] = useDisclosure(false);
    const [records, setRecords] = useState([]);
    const [query, setQuery] = useState('');
    const [debouncedQuery] = useDebouncedValue(query, 200);


    useEffect(() => {
        refresh()
    }, [currentRecord]);

    function openModal(rec) {
        setcurrentRecord(rec)
        open()
    }
    function closeModal() {
        setcurrentRecord({})
        close()

    }
    function refresh() {
        API.get("AdminApi", "/rsvp")
            .then(response => {
                setQuery('')
                setRsvpData(response);
                setRecords(response)

            })
            .catch(error => {
            })
        API.get("AdminApi", "/guests")
            .then(response => {
                setGuestData(response);

            })
            .catch(error => {
            })
    }

    useEffect(() => {
        setRecords(
            rsvpData.filter(({ name }) => {
                if (
                    debouncedQuery !== '' &&
                    !`${name}`.toLowerCase().includes(debouncedQuery.trim().toLowerCase())
                ) {
                    return false;
                }
                return true;
            })
        );
    }, [debouncedQuery, rsvpData]);



    return (
        <>
            <div style={{ margin: 'auto', width: '10%' }}>
                <h1>Rückmeldungen</h1>
            </div>
            <div style={{ width: "80%", margin: "auto", marginTop: "50px" }}>
                <div style={{ display: "flex", justifyContent: "space-between", marginBottom: "20px" }}>
                    <Button color='green' onClick={() => openModal()}>Add entry</Button>
                    <Button variant='outline' color='green' onClick={() => refresh()}>Refresh</Button>
                </div>
                <DataTable
                    withTableBorder
                    borderRadius="sm"
                    withColumnBorders
                    striped
                    highlightOnHover
                    // provide data
                    records={records || []}
                    // define columns
                    columns={[
                        {
                            accessor: 'id',
                            // this column has a custom title
                            title: '#',
                            // right-align column
                            textAlign: 'right',
                        },
                        {
                            accessor: 'name',
                            filter: (
                                <TextInput
                                    label="Name"
                                    description=""
                                    placeholder="Gast suchen"
                                    leftSection={<IconSearch size={16} />}
                                    rightSection={
                                        <ActionIcon size="sm" variant="transparent" c="dimmed" onClick={() => setQuery('')}>
                                            <IconX size={14} />
                                        </ActionIcon>
                                    }
                                    value={query}
                                    onChange={(e) => setQuery(e.currentTarget.value)}
                                />
                            ),
                            filtering: query !== '',
                        },
                        {
                            accessor: 'attendance',
                            title: 'Teilnahme',
                            render: (record) => (
                                record.attendance ? <IconCheck width={'20'} /> : <IconSquareX width={'20'} />
                            ),
                            textAlign: 'center'
                        },
                        {
                            accessor: 'guests',
                            title: 'Gäste',
                            textAlign:'center'
                        },
                        { accessor: 'phone' },
                        { accessor: 'email' },
                        {
                            accessor: 'aktion',
                            textAlign: 'center',
                            render: (record) => (
                                <Group gap={4} justify="center" wrap="nowrap">
                                    <ActionIcon
                                        size="sm"
                                        variant="subtle"
                                        color="blue"
                                        onClick={() => openModal(record)}
                                    >
                                        <IconEdit size={16} />
                                    </ActionIcon>
                                </Group>
                            ),
                        },


                        /*
                        {
                            accessor: 'invitedEvents',
                            render: ({ invitedEvents }) => (
                                Object.keys(invitedEvents).map((event, index) => (
                                    invitedEvents[event] === true ? (
                                        <li key={index}>{event}</li>
                                    )
                                        : (null)
                                ))
                            ),
                        },
                        */

                    ]}
                    rowExpansion={{
                        content: ({ record }) => (
                            <Stack gap={6}>
                                <Group gap={6}>
                                    <div style={{ width: "100%", margin: 'auto', alignItems: 'center' }}>
                                        <table width={"100%"} style={{ textAlign: "center" }}>
                                            <tr>
                                                <th>Name</th>
                                                <th>Standesamt</th>
                                                <th>Party</th>
                                                <th>Italien</th>
                                            </tr>

                                            {Object.keys(record.guestsDetail).map((detailKey, detailIndex) => (
                                                <tr key={detailIndex}>
                                                    <td>{record?.guestsDetail[detailKey]?.name}</td>
                                                    <td>{record?.guestsDetail[detailKey]?.ceremony === true ? <IconCheck width={'20'} /> : <IconSquareX width={'20'} />}</td>
                                                    <td>{record?.guestsDetail[detailKey]?.reception === true ? <IconCheck width={'20'} /> : <IconSquareX width={'20'} />}</td>
                                                    <td>{record?.guestsDetail[detailKey]?.italy === true ? <IconCheck width={'20'} /> : <IconSquareX width={'20'} />}</td>
                                                </tr>
                                            ))}
                                        </table>
                                        {console.log(record)}
                                        {
                                            Object.values(record.guestsDetail).some(
                                                (detail) => detail.hasOwnProperty("italy") && detail.italy === true
                                            ) ? (<>
                                                <div style={{ width: "20px", margin: "auto" }}>
                                                    <h3>Toskana:</h3>
                                                </div>
                                                <table width={"100%"} style={{ textAlign: "center" }}>
                                                    <tr>
                                                        <th>Name</th>
                                                        <th>Nächte</th>
                                                        <th>Frühstück</th>
                                                    </tr>

                                                    {Object.entries(record.guestsDetail)
                                                        .filter(([key, detail]) => detail.hasOwnProperty("italy") && detail.italy === true)
                                                        .map(([detailKey, detail], detailIndex) => (
                                                            <tr key={detailIndex}>
                                                                <td>{record?.guestsDetail[detailKey]?.name}</td>
                                                                <td>{record?.guestsDetail[detailKey]?.days?.toString()}</td>
                                                                <td>{record?.guestsDetail[detailKey]?.breakfast === true ? <IconCheck width={'20'} /> : <IconSquareX width={'20'} />}</td>
                                                            </tr>
                                                        ))}
                                                </table>
                                            </>
                                            ) : (null)}

                                    </div>
                                </Group>
                            </Stack>
                        ),
                    }}
                />
            </div>

            <Modal opened={modalOpened} title={currentRecord?.name} onClose={closeModal} centered>
                <InsertUpdateRsvpDialog record={currentRecord} close={closeModal} refresh={refresh} allGuests={guestData} />
            </Modal>
        </>
    );
}

export default Rsvplist;
/*
<Modal opened={modalOpened} title={currentRecord?.name} onClose={closeModal} centered>
<InsertUpdateGuestDialog record={currentRecord} />
</Modal>*/