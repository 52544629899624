import React from 'react';
import { useState, useEffect } from 'react';
import { DataTable } from 'mantine-datatable';
import { Box } from '@mantine/core';
import { showNotification } from '@mantine/notifications';
import { API } from 'aws-amplify';
import Guestlist from '../components/guestlist';
function Guests({ }) {

    return (
        <Guestlist/>
    );
}

export default Guests;
